import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./Footer.scss";
import { FormattedMessage } from "react-intl";
function Footer() {
    const { pathname } = useLocation();
    const getFooterClasses = () => {
        return pathname === '/' ? 'footer-container white-background' : 'footer-container';
    };
    function getYear() {
        return new Date().getFullYear();
    }
    return (React.createElement("div", { className: getFooterClasses() },
        React.createElement("div", { className: "footer-logo" },
            React.createElement("img", { src: "/assets/logo-k.png" })),
        React.createElement("footer", null,
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "columns is-full-desktop is-half-mobile zen is-vcentered" },
                    React.createElement("div", { className: "columns zen-item column is-one-quarter-desktop is-half-mobile is-vcentered" },
                        React.createElement("div", { className: "column is-half icon icon-lock" }, "\u00A0"),
                        React.createElement("div", { className: "column is-half" },
                            React.createElement(FormattedMessage, { id: "Paiement s\u00E9curis\u00E9", defaultMessage: "Paiement S\u00E9curis\u00E9", values: { br: React.createElement("br", null), }, tagName: "p" }))),
                    React.createElement("div", { className: "columns zen-item column is-one-quarter-desktop is-full-mobile is-vcentered" },
                        React.createElement("div", { className: "column is-half icon icon-truck" }, "\u00A0"),
                        React.createElement("div", { className: "column is-half" },
                            React.createElement(FormattedMessage, { id: "Livraison rapide", defaultMessage: "Livraison Rapide", values: { br: React.createElement("br", null) }, tagName: "p" }))),
                    React.createElement("div", { className: "columns zen-item column is-one-quarter-desktop is-full-mobile is-vcentered" },
                        React.createElement("div", { className: "column is-half icon icon-mail" }, "\u00A0"),
                        React.createElement("div", { className: "column is-half" },
                            React.createElement(FormattedMessage, { id: "Disponible 24/7", defaultMessage: "Disponible 24/7", values: { br: React.createElement("br", null) }, tagName: "p" }))),
                    React.createElement("div", { className: "columns zen-item column is-one-quarter-desktop is-full-mobile is-vcentered" },
                        React.createElement("div", { className: "column is-half icon icon-smiley" }, "\u00A0"),
                        React.createElement("div", { className: "column is-half" },
                            React.createElement(FormattedMessage, { id: "Exclusif Coiffeurs", defaultMessage: "Exclusif Coiffeurs", values: { br: React.createElement("br", null) }, tagName: "p" })))),
                React.createElement("div", { className: "columns is-mobile" },
                    React.createElement("div", { className: "column menu-footer" },
                        React.createElement("strong", null,
                            React.createElement(FormattedMessage, { id: "MON COMPTE", defaultMessage: "MON COMPTE" })),
                        React.createElement("ul", null,
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/authentification" },
                                    React.createElement(FormattedMessage, { id: "Connexion", defaultMessage: "Connexion" }))),
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/mon-compte/profile" },
                                    React.createElement(FormattedMessage, { id: "Mes Infos", defaultMessage: "Mes Infos" }))),
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/mon-compte/commandes" },
                                    React.createElement(FormattedMessage, { id: "Mes Commandes", defaultMessage: "Mes Commandes" }))),
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/mon-compte/commandes" },
                                    React.createElement(FormattedMessage, { id: "Mes Factures", defaultMessage: "Mes Factures" }))),
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/mon-compte/documents" },
                                    React.createElement(FormattedMessage, { id: "Mes Documents", defaultMessage: "Mes Documents" }))))),
                    React.createElement("div", { className: "column menu-footer" },
                        React.createElement("strong", null,
                            React.createElement(FormattedMessage, { id: "LES COLORATIONS", defaultMessage: "LES COLORATIONS" })),
                        React.createElement("ul", null,
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/pages/coloration-essential" },
                                    React.createElement(FormattedMessage, { id: "La coloration ESSENTIAL", defaultMessage: "La coloration ESSENTIAL" }))),
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/pages/coloration-pure" },
                                    React.createElement(FormattedMessage, { id: "La coloration PURE", defaultMessage: "La coloration PURE" }))),
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/pages/coloration-armonia" },
                                    React.createElement(FormattedMessage, { id: "La coloration ARMONIA", defaultMessage: "La coloration ARMONIA" }))),
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/pages/patine-kontrol" },
                                    React.createElement(FormattedMessage, { id: "La coloration KONTROL", defaultMessage: "La coloration KONTROL" }))),
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/nos-produits/kroma" },
                                    React.createElement(FormattedMessage, { id: "La coloration KROMA", defaultMessage: "La coloration KROMA" }))))),
                    React.createElement("div", { className: "column columns is-multiline has-text-centered" },
                        React.createElement("span", { className: "column is-12" },
                            React.createElement(FormattedMessage, { id: "Retrouvez-nous sur", defaultMessage: "Retrouvez-nous sur" }),
                            "..."),
                        React.createElement("div", { className: "column columns is-multiline socials" },
                            React.createElement("div", { className: "column is-4" },
                                React.createElement("a", { target: "_blank", href: "https://www.facebook.com/kustofficiel", className: "facebook" }, "\u00A0")),
                            React.createElement("div", { className: "column is-4" },
                                React.createElement("a", { target: "_blank", href: "https://www.instagram.com/kust_officiel/?hl=fr", className: "instagram" }, "\u00A0")),
                            React.createElement("div", { className: "column is-4" },
                                React.createElement("a", { target: "_blank", href: "https://www.tiktok.com/@kust_officiel?lang=fr", className: "tiktok" }, "\u00A0"))),
                        React.createElement("div", { className: "column is-12" },
                            React.createElement("small", null,
                                "\u00A9 ",
                                getYear(),
                                React.createElement(FormattedMessage, { id: "KUST marque d\u00E9pos\u00E9e", defaultMessage: "KUST marque d\u00E9pos\u00E9e" }),
                                React.createElement(FormattedMessage, { id: "Tous Droits R\u00E9serv\u00E9s", defaultMessage: "Tous Droits R\u00E9serv\u00E9s" })))),
                    React.createElement("div", { className: "column has-text-right menu-footer" },
                        React.createElement("strong", null,
                            React.createElement(FormattedMessage, { id: "EN SAVOIR PLUS", defaultMessage: "EN SAVOIR PLUS" })),
                        React.createElement("ul", null,
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/pages/a-propos" },
                                    React.createElement(FormattedMessage, { id: "KUST, c\u2019est quoi ?", defaultMessage: "KUST, c\u2019est quoi ?" }))),
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/pages/qui-peut-acheter-chez-kust" },
                                    React.createElement(FormattedMessage, { id: "Qui peut acheter chez KUST ?", defaultMessage: "Qui peut acheter chez KUST ?" }))),
                            React.createElement("li", null,
                                React.createElement("a", { target: "_blank", href: "https://www.facebook.com/groups/kust.community/" },
                                    React.createElement(FormattedMessage, { id: "La communaut\u00E9 KUST", defaultMessage: "La communaut\u00E9 KUST" }))),
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/pages/partenariats" },
                                    React.createElement(FormattedMessage, { id: "Partenariats", defaultMessage: "Partenariats" }))))),
                    React.createElement("div", { className: "column has-text-right menu-footer" },
                        React.createElement("strong", null,
                            React.createElement(FormattedMessage, { id: "INFORMATIONS", defaultMessage: "INFORMATIONS" })),
                        React.createElement("ul", null,
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/pages/qui-sommes-nous" },
                                    React.createElement(FormattedMessage, { id: "Qui sommes-nous", defaultMessage: "Qui sommes-nous" }))),
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/pages/mentions-legales" },
                                    React.createElement(FormattedMessage, { id: "Mentions L\u00E9gales", defaultMessage: "Mentions L\u00E9gales" }))),
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/pages/confidentialite" },
                                    React.createElement(FormattedMessage, { id: "Confidentialit\u00E9", defaultMessage: "Confidentialit\u00E9" }))),
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/pages/nous-contacter" },
                                    React.createElement(FormattedMessage, { id: "Nous contacter", defaultMessage: "Nous contacter" }))),
                            React.createElement("li", null,
                                React.createElement("a", { href: "javascript:openAxeptioCookies()" }, "Cookies")),
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/pages/cgv" },
                                    React.createElement(FormattedMessage, { id: "CGV", defaultMessage: "CGV" }))))))))));
}
export default Footer;
